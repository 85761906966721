import React from 'react';
import { Heading, PropertyGroup } from '../../components';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionMultiEnumMaybe = props => {
  const { heading, options, selectedOptions, publicData } = props;
  if (!heading || !options || !selectedOptions) {
    return null;
  }

  return (
    <>
      <div className={css.sectionMultiEnum}>
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
        <PropertyGroup
          id="ListingPage.amenities"
          options={options}
          selectedOptions={selectedOptions}
          twoColumns={options.length > 5}
          className={classNames(heading === "Countries to work" &&  css.countryList, heading === "Languages" && css.countryList)}
        />
      </div>
      {heading === 'Career Level' ? (
        <div className={css.sectionMultiEnum}>
          <Heading as="h2" rootClassName={css.sectionHeading}>
            Minimum Team-size for Personnel Responsibility
          </Heading>
          <div className={css.textItem}> {publicData.team_size}</div>
        </div>
      ) : null}
    </>
  );
};

export default SectionMultiEnumMaybe;
